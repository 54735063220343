import React, { FC } from 'react';
import { Record } from 'react-admin';
import { ModalImage } from 'react-modal-image';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const useStyles = makeStyles({
  imageContainer: {
    display: 'inline-block',
  },
  imageSmall: {
    maxWidth: ({ width }: { width: number }) => `${width}rem !important`,
    verticalAlign: 'middle',
  },
});

/* eslint-disable */
export const ImageField: FC<{
  record: Omit<Record, 'id'>;
  source: string;
  alt: string;
  src?: string;
  width?: number;
}> = ({ record, source, src, width = 3, alt }) => {
  const classes = useStyles({ width });
  const imageSource = src || _.get(record, source);

  return (
    <div key={imageSource} className={classes.imageContainer}>
      <ModalImage small={imageSource} large={imageSource} className={classes.imageSmall} alt={alt} />
    </div>
  );
};
