import { AssignmentTurnedIn } from '@material-ui/icons';
import { ResourceProps } from 'react-admin';
import { OrderList } from './OrderList';

const options = {
  label: 'Orders',
};

export const ordersResourceProps: Omit<ResourceProps, 'name'> = {
  list: OrderList,
  icon: AssignmentTurnedIn,
  options,
};
