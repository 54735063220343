import { isValidElement, Children, useCallback, cloneElement, useEffect, useState, FC, useRef } from 'react';
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  makeStyles,
  TableFooter,
  Typography,
} from '@material-ui/core';
import {
  DatagridBody,
  DatagridBodyProps,
  DatagridHeaderCell,
  DatagridLoading,
  DatagridProps,
  PureDatagridBody,
  sanitizeListRestProps,
  SortPayload,
  Record,
  DatagridHeaderCellProps,
  Identifier,
} from 'react-admin';
import classnames from 'classnames';
import union from 'lodash/union';
import difference from 'lodash/difference';

const useStyles = makeStyles((theme) => ({
  table: {
    tableLayout: 'auto',
  },
  thead: {},
  tbody: {},
  tfoot: {},
  headerRow: {},
  headerCell: {},
  checkbox: {},
  row: {},
  clickableRow: {
    cursor: 'pointer',
  },
  rowEven: {},
  rowOdd: {},
  rowCell: {},
  expandHeader: {
    padding: 0,
    width: theme.spacing(6),
  },
  expandIconCell: {
    width: theme.spacing(6),
  },
  expandIcon: {
    padding: theme.spacing(1),
    transform: 'rotate(-90deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expanded: {
    transform: 'rotate(0deg)',
  },
}));

/* eslint-disable */
type IDatagridProps = DatagridProps &
  DatagridBodyProps &
  Partial<DatagridHeaderCellProps> & {
    onSelectedDataChange: (selectedData: Record[]) => void;
    loaded?: boolean;
    loading?: boolean;
    setSort?: (sort: SortPayload) => void;
    onSelect?: (ids: Identifier[]) => void;
    totalDueNotPaid: number;
  };

export const SupplierOrdersDatagrid: FC<IDatagridProps> = ({
  onSelectedDataChange,
  totalDueNotPaid,
  classes,
  basePath,
  optimized = false,
  body = optimized ? <PureDatagridBody /> : <DatagridBody />,
  children,
  className,
  currentSort = { field: '_id', order: 'ASC' },
  data = {},
  expand,
  hasBulkActions,
  hover,
  ids = [],
  loading,
  loaded,
  onSelect,
  onToggleItem,
  resource = 'orders',
  rowClick,
  rowStyle,
  selectedIds = [],
  setSort = () => {},
  size = 'small',
  isRowSelectable,
  ...rest
}) => {
  const datagridClasses = useStyles({ classes });

  const [selectedData, setSelectedData] = useState<Record[]>([]);

  useEffect(() => {
    return onSelectedDataChange(selectedData);
  }, [selectedData, onSelectedDataChange]);

  useEffect(() => {
    setSelectedData((sData) =>
      sData
        .filter((sd) => selectedIds.includes(sd.id))
        .concat(selectedIds.filter((id) => !sData.some((sd) => sd.id === id)).map((id) => data[id])),
    );
  }, [selectedIds, data]);

  const updateSort = useCallback(
    (event) => {
      event.stopPropagation();
      setSort(event.currentTarget.dataset.sort);
    },
    [setSort],
  );

  const handleSelectAll = useCallback(
    (event) => {
      if (!onSelect) return;
      if (event.target.checked) {
        onSelect(ids.concat(selectedIds.filter((id) => !ids.includes(id))));
      } else {
        onSelect([]);
      }
    },
    [ids, onSelect, selectedIds],
  );

  const lastSelected = useRef(null);

  useEffect(() => {
    if (selectedIds.length === 0) {
      lastSelected.current = null;
    }
  }, [selectedIds.length]);

  const handleToggleItem = useCallback(
    (id, event) => {
      const lastSelectedIndex = ids.indexOf(lastSelected.current as any);
      lastSelected.current = event.target.checked ? id : null;

      if (event.shiftKey && lastSelectedIndex !== -1) {
        const index = ids.indexOf(id);
        const idsBetweenSelections = ids.slice(
          Math.min(lastSelectedIndex, index),
          Math.max(lastSelectedIndex, index) + 1,
        );

        const newSelectedIds = event.target.checked
          ? union(selectedIds, idsBetweenSelections)
          : difference(selectedIds, idsBetweenSelections);

        if (!onSelect) return;
        onSelect(
          isRowSelectable ? newSelectedIds.filter((id: Identifier) => isRowSelectable(data[id])) : newSelectedIds,
        );
      } else {
        if (!onToggleItem) return;
        onToggleItem(id);
      }
    },
    [data, ids, isRowSelectable, onSelect, onToggleItem, selectedIds],
  );

  /**
   * if loaded is false, the list displays for the first time, and the dataProvider hasn't answered yet
   * if loaded is true, the data for the list has at least been returned once by the dataProvider
   * if loaded is undefined, the Datagrid parent doesn't track loading state (e.g. ReferenceArrayField)
   */
  if (loaded === false) {
    return (
      <DatagridLoading
        classes={datagridClasses}
        className={className}
        expand={expand}
        hasBulkActions={hasBulkActions}
        nbChildren={Children.count(children)}
        size={size}
      />
    );
  }

  /**
   * Once loaded, the data for the list may be empty. Instead of
   * displaying the table header with zero data rows,
   * the datagrid displays nothing in this case.
   */
  if (loaded && ids.length === 0) {
    return null;
  }

  /**
   * After the initial load, if the data for the list isn't empty,
   * and even if the data is refreshing (e.g. after a filter change),
   * the datagrid displays the current data.
   */
  return (
    <Table className={classnames(datagridClasses.table, className)} size={size} {...sanitizeListRestProps(rest)}>
      <TableHead className={datagridClasses.thead}>
        <TableRow className={classnames(datagridClasses.row, datagridClasses.headerRow)}>
          {expand && <TableCell padding="none" className={datagridClasses.expandHeader} />}
          {hasBulkActions && (
            <TableCell padding="checkbox">
              <Checkbox
                className="select-all"
                color="primary"
                checked={selectedIds.length > 0 && ids.length > 0 && ids.every((id) => selectedIds.includes(id))}
                onChange={handleSelectAll}
              />
            </TableCell>
          )}
          {/** @ts-ignore */}
          {Children.map(children, (field: { props: { sortBy: string; source: string } }, index) =>
            isValidElement(field) ? (
              <DatagridHeaderCell
                className={datagridClasses.headerCell}
                currentSort={currentSort}
                field={field}
                isSorting={currentSort.field === (field.props.sortBy || field.props.source)}
                key={field.props.source || index}
                resource={resource}
                updateSort={updateSort}
              />
            ) : null,
          )}
        </TableRow>
      </TableHead>
      {cloneElement(
        body as any,
        {
          basePath,
          className: datagridClasses.tbody,
          classes: datagridClasses,
          expand,
          rowClick,
          data,
          hasBulkActions,
          hover,
          ids,
          onToggleItem: handleToggleItem,
          resource,
          rowStyle,
          selectedIds,
          isRowSelectable,
        },
        children,
      )}
      <TableFooter>
        <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell align="right" variant="footer">
            <Typography component="div" color="textPrimary">
              Total not paid out:
            </Typography>
          </TableCell>
          <TableCell align="right" variant="footer">
            <Typography component="div" color="textPrimary">
              ${totalDueNotPaid.toFixed(2)}
            </Typography>
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
};
