import React, { FC } from 'react';
import { AutocompleteInput, Filter, FilterProps, ReferenceInput, SelectInput, TextInput } from 'react-admin';

export const OrderFilters: FC<Omit<FilterProps, 'children'>> = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <ReferenceInput source="shopId" reference="retailer-names" alwaysOn sort={{ field: 'name', order: 'ASC' }}>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <SelectInput
      source="status"
      choices={[
        { id: 'fulfilled', name: 'Fulfilled' },
        { id: 'partial', name: 'Partial' },
        { id: 'payment failed', name: 'Payment Failed' },
        { id: 'unfulfilled', name: 'Unfulfilled' },
        { id: 'cancelled', name: 'Cancelled' },
        { id: 'error', name: 'Error' },
      ]}
      optionText="name"
      alwaysOn
    />
  </Filter>
);
