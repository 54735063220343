import { ResourceProps } from 'react-admin';
import { PermIdentity } from '@material-ui/icons';
import { AdminCreate } from './AdminCreate';
import { AdminEdit } from './AdminEdit';
import { AdminList } from './AdminList';

const options = {
  label: 'Admins',
};

export const adminResourceProps: Omit<ResourceProps, 'name'> = {
  list: AdminList,
  edit: AdminEdit,
  create: AdminCreate,
  icon: PermIdentity,
  options,
};
