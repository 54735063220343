import { ResourceProps } from 'react-admin';
import { PermIdentity } from '@material-ui/icons';
import { SupplierList } from './SupplierList';
import { SupplierEdit } from './SupplierEdit';

const options = {
  label: 'Suppliers',
};

export const supplierResourceProps: Omit<ResourceProps, 'name'> = {
  edit: SupplierEdit,
  list: SupplierList,
  icon: PermIdentity,
  options,
};
