import React, { FC } from 'react';
import { BooleanField, Datagrid, DateField, EditButton, List, ListProps, NumberField, TextField } from 'react-admin';
import UrlField from '../../components/UrlField';
import { RetailerFilters } from './RetailerFilters';

export const RetailerList: FC<ListProps> = (props) => (
  <List bulkActionButtons={false} filters={<RetailerFilters />} {...props}>
    <Datagrid>
      <UrlField source="name" linkSource="shop" />
      <TextField label="Orders" source="ordersAmount" />
      <TextField label="Products" source="productsAmount" />
      <DateField label="Last order date" source="lastOrderDate" />
      <NumberField source="revenue" label="Revenue" options={{ style: 'currency', currency: 'USD' }} />
      <NumberField source="profit" label="Profit" options={{ style: 'currency', currency: 'USD' }} />
      <TextField source="currentPlan" />
      <DateField label="Plan renewal date" source="planRenewalDate" />
      <DateField label="Registration date" source="planRegistrationDate" />
      <BooleanField source="isLegacyPaidVisible" />
      <BooleanField source="isLegacyFreeVisible" />
      <EditButton />
    </Datagrid>
  </List>
);
