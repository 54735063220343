import {
  SimpleForm,
  TextInput,
  BooleanInput,
  number,
  Toolbar,
  SimpleFormProps,
  DateInput,
  FormDataConsumer,
  required,
} from 'react-admin';

export const PayoutForm = (props: Omit<SimpleFormProps, 'children'>) => (
  <SimpleForm {...props} toolbar={<Toolbar alwaysEnableSaveButton />}>
    <TextInput source="amount" disabled validate={number()} />
    <TextInput source="adjustmentAmount" validate={number()} />
    <TextInput source="adjustmentReason" />
    <TextInput source="confirmationId" label="Confirmation ID" />
    <BooleanInput source="paid" label="Mark as paid" defaultValue={false} />
    <FormDataConsumer>
      {({ formData, ...rest }) => formData.paid && <DateInput source="paidAt" {...rest} validate={required()} />}
    </FormDataConsumer>
  </SimpleForm>
);
