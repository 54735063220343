import { Typography } from '@material-ui/core';
import { FC, PropsWithChildren } from 'react';

const Wrapper: FC<PropsWithChildren<{}> | string> = ({ children }) => {
  return (
    <Typography variant="caption" component="p">
      {children}
    </Typography>
  );
};

interface TrackingNumbersProps {
  numbers: string[];
  urls: string[];
  companies: string[];
  isFulfilled: boolean;
}

const TrackingNumbers: FC<TrackingNumbersProps> = ({ numbers, urls, companies, isFulfilled }: TrackingNumbersProps) => {
  if (!isFulfilled) return <Wrapper>Pending</Wrapper>;

  if (!numbers.length && !companies.length) return <Wrapper>Not provided</Wrapper>;

  if (!numbers.length) {
    return (
      <>
        {companies.map((company) => {
          return (
            <Wrapper key={company}>
              <Typography>{company}</Typography>
            </Wrapper>
          );
        })}
      </>
    );
  }

  return (
    <div>
      {numbers.map((number, index) => (
        <Wrapper key={number}>
          {urls[index] ? (
            <a href={urls[index]} target="_blank" rel="noreferrer">
              {number}
            </a>
          ) : (
            number
          )}
        </Wrapper>
      ))}
    </div>
  );
};
export default TrackingNumbers;
