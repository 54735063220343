import { Add } from '@material-ui/icons';
import { FC } from 'react';
import {
  Button,
  Datagrid,
  DateField,
  EditButton,
  ExportButton,
  Link,
  List,
  ListProps,
  NumberField,
  ShowButton,
  TextField,
} from 'react-admin';
import { EmptyList } from '../../components/EmptyList';
import { LinkToOrdersButton } from '../../components/LinkToOrdersButton';

const GoToSuppliersButton = () => (
  <Button label="Go to suppliers and create a payout" component={Link} to="/payouts/suppliers">
    <Add />
  </Button>
);

export const PayoutList: FC<ListProps> = (props) => (
  <List
    bulkActionButtons={false}
    {...props}
    empty={<EmptyList title="No payouts yet." content={<GoToSuppliersButton />} />}
    actions={
      <div>
        <GoToSuppliersButton />
        <ExportButton />
      </div>
    }
  >
    <Datagrid>
      <TextField label="Supplier" source="supplierName" />
      <DateField label="Payout creation date" source="createdAt" />
      <TextField label="Status" source="status" />
      <TextField label="Confirmation ID" source="confirmationId" />
      <NumberField source="payoutAmount" label="Payout amount" options={{ style: 'currency', currency: 'USD' }} />
      <LinkToOrdersButton pathname="/payouts/orders" recordName="payoutId" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);
