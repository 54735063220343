import React, { FC } from 'react';
import { Datagrid, DeleteButton, List, ListProps, TextField } from 'react-admin';

export const AdminList: FC<ListProps> = (props) => (
  <List bulkActionButtons={false} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="username" />
      <DeleteButton mutationMode="optimistic" />
    </Datagrid>
  </List>
);
