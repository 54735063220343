import { DataProvider, fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

export default (apiUrl: string, httpClient = fetchUtils.fetchJson): DataProvider => ({
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      ...params.filter,
      field: field === 'id' ? '_id' : field,
      order: order.toLowerCase(),
      offset: (page - 1) * perPage,
      limit: perPage,
      ...(window.location.href.includes('suppliers/orders') &&
        !!localStorage.getItem('totalOffset') && {
          reqTotalOffset: localStorage.getItem('totalOffset'),
        }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const { json } = await httpClient(url);
    if (json.totalOffset) localStorage.setItem('totalOffset', json.totalOffset);
    return {
      data: json.data.map(({ _id: id, ...record }: any) => ({ id, ...record })),
      total: json.total,
    };
  },

  getMany: async (resource, params) => {
    const url = `${apiUrl}/${resource}?_id=${params.ids.join(',')}`;
    const { json } = await httpClient(url);

    return {
      data: json.data.map(({ _id: id, ...record }: any) => ({ id, ...record })),
      total: json.total,
    };
  },

  getOne: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;
    const {
      json: { _id: id, ...record },
    } = await httpClient(url);

    return { data: { id, ...record } };
  },

  create: async (resource, params) => {
    const url = `${apiUrl}/${resource}`;
    const options = { method: 'POST', body: JSON.stringify(params.data) };
    const {
      json: { _id: id, ...record },
    } = await httpClient(url, options);

    return { data: { id, ...record } };
  },

  update: async (resource, params) => {
    const url = `${apiUrl}/${resource}`;
    const options = { method: 'PUT', body: JSON.stringify(params.data) };
    const {
      json: { _id: id, ...record },
    } = await httpClient(url, options);

    return { data: { id, ...record } };
  },

  delete: async (resource, params) => {
    const url = `${apiUrl}/${resource}`;
    const options = {
      method: 'DELETE',
      body: JSON.stringify({ id: params.id }),
    };
    await httpClient(url, options);

    return { data: params.previousData as any };
  },

  getManyReference: () => {
    return Promise.reject(new Error('getManyReference DataProvider method is not implemented'));
  },

  updateMany: () => {
    return Promise.reject(new Error('updateMany DataProvider method is not implemented'));
  },

  deleteMany: () => {
    return Promise.reject(new Error('deleteMany DataProvider method is not implemented'));
  },
});
