import { Inbox } from '@material-ui/icons';
import { Box, Typography } from '@material-ui/core';
import { FC, ReactNode } from 'react';

/* eslint-disable */
type EmptyListProps = {
  title: string;
  content?: ReactNode;
};

export const EmptyList: FC<EmptyListProps> = ({ title, content }) => (
  <Box textAlign="center" m={1} style={{ padding: '2em' }}>
    <Inbox style={{ height: '9em', width: '9em', opacity: 0.5 }} />
    <Typography variant="h4" paragraph style={{ opacity: 0.5, marginBottom: '1em' }}>
      {title}
    </Typography>
    {content}
  </Box>
);
