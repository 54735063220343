import {
  Card,
  CardContent,
  Chip,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { OpenInNew } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import _ from 'lodash';
import React, { FC } from 'react';
import { NumberField, ShowProps, TextField, useShowController, FunctionField } from 'react-admin';
import { ImageField } from '../../components/ImageField';
import { IOrderDetails } from '../../types/IOrder';
import TrackingNumbers from '../../components/TrackingNumbers';

const useStyles = makeStyles({
  root: { width: '65%', margin: 'auto', backgroundColor: '#313131' },
  list: { padding: 0, width: '100%' },
  listContainer: { padding: 15 },
  listChip: { marginBottom: 8, borderRadius: 5, fontWeight: 700 },
  image: { maxHeight: 50 },
  spacer: { height: 20 },
  products: { margin: '10px 0' },
  rightAlignedCell: { textAlign: 'right' },
});

export const OrderShow: FC<ShowProps> = (props) => {
  const { record } = useShowController<IOrderDetails>(props);
  const classes = useStyles();

  if (!record) return null;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>
              Order
            </Typography>
            <Link href={record.orderLink} gutterBottom target="_blank" rel="noopener noreferrer">
              Open in the shop <OpenInNew fontSize="inherit" />
            </Link>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom align="center">
              Status
            </Typography>
            <Typography gutterBottom align="center">
              {_.startCase(record.status as string)}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom align="right">
              Order name
            </Typography>
            <TextField source="orderName" align="right" component="p" gutterBottom />
          </Grid>
        </Grid>

        <div className={classes.spacer}>&nbsp;</div>

        <Typography variant="h6" gutterBottom>
          Shipping address
        </Typography>
        <Grid item xs={12}>
          {record.shippingAddress ? (
            <Paper className={classes.listContainer}>
              <List className={classes.list}>
                <ListItem dense divider>
                  <ListItemText>Customer</ListItemText>
                  <TextField source="shippingAddress.name" />
                </ListItem>
                <ListItem dense divider>
                  <ListItemText>Line 1</ListItemText>
                  <TextField source="shippingAddress.address1" />
                </ListItem>
                <ListItem dense divider>
                  <ListItemText>Line 2</ListItemText>
                  <TextField source="shippingAddress.address2" />
                </ListItem>
                <ListItem dense divider>
                  <ListItemText>City</ListItemText>
                  <TextField source="shippingAddress.city" />
                </ListItem>
                <ListItem dense divider>
                  <ListItemText>Province / State</ListItemText>
                  <TextField source="shippingAddress.province" />
                </ListItem>
                <ListItem dense divider>
                  <ListItemText>Country</ListItemText>
                  <TextField source="shippingAddress.country" />
                </ListItem>
                <ListItem dense>
                  <ListItemText>Zip Code</ListItemText>
                  <TextField source="shippingAddress.zip" />
                </ListItem>
              </List>
            </Paper>
          ) : (
            <Typography gutterBottom align="center">
              No shipping address provided
            </Typography>
          )}
        </Grid>
        <div className={classes.spacer}>&nbsp;</div>
        <Typography variant="h6" gutterBottom>
          Totals
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {record.totals?.retailer ? (
              <Paper className={classes.listContainer}>
                <Chip
                  label="Retailer"
                  size="small"
                  className={classes.listChip}
                  style={{ backgroundColor: '#13A043' }}
                />
                <List className={classes.list}>
                  <ListItem dense divider>
                    <ListItemText>Subtotal</ListItemText>
                    <FunctionField
                      render={(records: any) =>
                        records && (records.totals.retailer.subtotal || records.totals.retailer.subtotal === 0)
                          ? `$${records.totals.retailer.subtotal.toFixed(2)}`
                          : null
                      }
                    />
                  </ListItem>
                  <ListItem dense divider>
                    <ListItemText>Shipping</ListItemText>
                    <FunctionField
                      render={(records: any) =>
                        records && (records.totals.retailer.shipping || records.totals.retailer.shipping === 0)
                          ? `$${records.totals.retailer.shipping.toFixed(2)}`
                          : null
                      }
                    />
                  </ListItem>
                  <ListItem dense divider>
                    <ListItemText>Refund</ListItemText>
                    <FunctionField
                      render={(records: any) =>
                        records && (records.totals.retailer.refunded || records.totals.retailer.refunded === 0)
                          ? `$${records.totals.retailer.refunded.toFixed(2)}`
                          : null
                      }
                    />
                  </ListItem>
                  <ListItem dense>
                    <ListItemText>Total</ListItemText>
                    <FunctionField
                      render={(records: any) =>
                        records && (records.totals.retailer.total || records.totals.retailer.total === 0)
                          ? `$${records.totals.retailer.total.toFixed(2)}`
                          : null
                      }
                    />
                  </ListItem>
                </List>
              </Paper>
            ) : (
              <Skeleton variant="rect" height={210} />
            )}
          </Grid>

          <Grid item xs={6}>
            {record.totals?.supplier ? (
              <Paper className={classes.listContainer}>
                <Chip label="Supplier" color="secondary" size="small" className={classes.listChip} />
                <List className={classes.list}>
                  <ListItem dense divider>
                    <ListItemText>Subtotal</ListItemText>
                    <FunctionField
                      render={(records: any) =>
                        records && (records.totals.supplier.subtotal || records.totals.supplier.subtotal === 0)
                          ? `$${records.totals.supplier.subtotal.toFixed(2)}`
                          : null
                      }
                    />
                  </ListItem>
                  <ListItem dense divider>
                    <ListItemText>Shipping</ListItemText>
                    <FunctionField
                      render={(records: any) =>
                        records && (records.totals.supplier.shipping || records.totals.supplier.shipping === 0)
                          ? `$${records.totals.supplier.shipping.toFixed(2)}`
                          : null
                      }
                    />
                  </ListItem>
                  <ListItem dense divider>
                    <ListItemText>Fee</ListItemText>
                    <FunctionField
                      render={(records: any) =>
                        records && (records.totals.supplier.fee || records.totals.supplier.fee === 0)
                          ? `$${records.totals.supplier.fee.toFixed(2)}`
                          : null
                      }
                    />
                  </ListItem>
                  <ListItem dense>
                    <ListItemText>Total</ListItemText>
                    <FunctionField
                      render={(records: any) =>
                        records && (records.totals.supplier.total || records.totals.supplier.total === 0)
                          ? `$${records.totals.supplier.total.toFixed(2)}`
                          : null
                      }
                    />
                  </ListItem>
                </List>
              </Paper>
            ) : (
              <Skeleton variant="rect" height={210} />
            )}
          </Grid>

          <Grid item xs={12}>
            {record.totals ? (
              <Paper className={classes.listContainer}>
                <List className={classes.list}>
                  <ListItem dense divider>
                    <ListItemText>Margin</ListItemText>
                    <FunctionField
                      render={(records: any) =>
                        records && records.totals.margin ? `$${records.totals.margin.toFixed(2)}` : '-'
                      }
                    />
                  </ListItem>
                  <ListItem dense divider>
                    <ListItemText>Profit</ListItemText>
                    <FunctionField
                      sortBy="totals.profit"
                      render={(records: any) =>
                        // eslint-disable-next-line no-nested-ternary
                        records
                          ? records.totals.profit < 0
                            ? String(records.totals.profit.toFixed(2)).replace(/-/, '-$')
                            : `$${records.totals.profit.toFixed(2)}`
                          : null
                      }
                    />
                  </ListItem>
                  <ListItem dense divider>
                    <ListItemText>Fee, %</ListItemText>
                    <NumberField source="totals.feePercent" />
                  </ListItem>
                  <ListItem dense divider>
                    <ListItemText>Shipping</ListItemText>
                    <TextField source="totals.selectedShipping" />
                  </ListItem>
                  <ListItem dense>
                    <ListItemText>Invoice</ListItemText>
                    {record.invoiceId ? (
                      <Link href={record.invoiceDownloadLink} gutterBottom target="_blank" rel="noopener noreferrer">
                        {record.invoiceId} <OpenInNew fontSize="inherit" />
                      </Link>
                    ) : (
                      <Typography variant="body2" align="center">
                        Invoice not created
                      </Typography>
                    )}
                  </ListItem>
                </List>
              </Paper>
            ) : (
              <Skeleton variant="rect" height={177} />
            )}
          </Grid>
        </Grid>

        <div className={classes.spacer}>&nbsp;</div>

        <Typography variant="h6" gutterBottom>
          Products
        </Typography>
        <div className={classes.products}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Product name</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Supplier</TableCell>
                <TableCell className={classes.rightAlignedCell}>Fulfilled/Total</TableCell>
                <TableCell className={classes.rightAlignedCell}>Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {record.products.map((product) => (
                <TableRow key={product.productId} hover>
                  <TableCell>
                    <div>
                      <Typography>{product.title}</Typography>
                      <Typography variant="caption">{product.sku}</Typography>
                    </div>
                  </TableCell>
                  <TableCell className={classes.image}>
                    <ImageField record={product} source="image" alt={product.title} />
                  </TableCell>
                  <TableCell>
                    <div>
                      <Typography>{product.supplier}</Typography>
                      <Typography variant="caption">
                        {product.supplierOrderName} / {product.supplierOrderId}
                      </Typography>
                      <TrackingNumbers
                        companies={product.trackingCompanies}
                        numbers={product.trackingNumbers}
                        urls={product.trackingUrls}
                        isFulfilled={!!product.fulfilledQty}
                      />
                    </div>
                  </TableCell>
                  <TableCell className={classes.rightAlignedCell}>
                    {product.fulfilledQty}/{product.totalQty}
                  </TableCell>
                  <TableCell className={classes.rightAlignedCell}>
                    $
                    {product.price
                      .toLocaleString(undefined, {
                        style: 'currency',
                        currency: 'USD',
                      })
                      .replace(/,/, '.')
                      .replace(/\$/, '')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};
