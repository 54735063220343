import React, { FC } from 'react';
import { Create, EditProps, email, Record, required, SimpleForm, TextInput } from 'react-admin';
import { confirmedPasswordValidation } from '../../helpers/validators';

export const AdminCreate: FC<EditProps> = (props) => {
  const transform = (data: Record) => {
    const { confirmPassword, ...editData } = data;
    return editData;
  };

  return (
    <Create mutationMode="pessimistic" transform={transform} {...props}>
      <SimpleForm redirect="list">
        <TextInput source="username" validate={email('Username must be a valid email')} />
        <TextInput source="password" type="password" validate={required()} />
        <TextInput source="confirmPassword" validate={[confirmedPasswordValidation, required()]} type="password" />
      </SimpleForm>
    </Create>
  );
};
