import React, { FC } from 'react';
import { Edit, EditProps, email, Record, SimpleForm, TextInput, TitleProps } from 'react-admin';
import { confirmedPasswordValidation } from '../../helpers/validators';

const AdminTitle: FC<TitleProps> = ({ record }) => <span>Editing Admin {record ? `"${record.username}"` : ''}</span>;

export const AdminEdit: FC<EditProps> = (props) => {
  const transform = (data: Record) => {
    const { confirmPassword, ...editData } = data;
    return editData;
  };

  return (
    <Edit title={<AdminTitle />} mutationMode="pessimistic" transform={transform} {...props}>
      <SimpleForm>
        <TextInput source="username" type="email" validate={email('Username must be a valid email')} />
        <TextInput source="password" type="password" />
        <TextInput source="confirmPassword" validate={confirmedPasswordValidation} type="password" />
      </SimpleForm>
    </Edit>
  );
};
