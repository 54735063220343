import _ from 'lodash';
import React, { FC } from 'react';
import { Datagrid, DateField, FunctionField, List, ListProps, TextField } from 'react-admin';
import { OrderFilters } from './OrderFilters';
import { OrderShow } from './OrderShow';

export const OrderList: FC<ListProps> = (props) => (
  <List bulkActionButtons={false} sort={{ field: 'processedAt', order: 'desc' }} filters={<OrderFilters />} {...props}>
    <Datagrid rowClick="expand" expand={<OrderShow />}>
      <TextField source="id" />
      <TextField label="Retailer order name" source="orderName" />
      <TextField label="Retailer shop name" source="name" />
      <DateField source="processedAt" showTime />
      <DateField source="createdAt" showTime />
      <FunctionField
        label="Price"
        sortBy="totalPrice"
        render={(record: any) =>
          record.totalPrice === undefined
            ? `$${record.totals.retailer.total.toFixed(2)}`
            : `$${record.totalPrice.toFixed(2)}`
        }
      />
      <FunctionField sortBy="status" label="Status" render={(record: any) => _.startCase(record.status)} />
    </Datagrid>
  </List>
);
