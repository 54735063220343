import React, { FC } from 'react';
import { Datagrid, DateField, List, ListProps, TextField, NumberField, EditButton, BooleanField } from 'react-admin';
import UrlField from '../../components/UrlField';

export const SupplierList: FC<ListProps> = (props) => (
  <List bulkActionButtons={false} {...props}>
    <Datagrid>
      <UrlField source="name" linkSource="shop" />
      <TextField label="Products" source="productsAmount" />
      <TextField label="Orders" source="ordersAmount" />
      <DateField label="Last order date" source="lastOrderDate" />
      <NumberField label="Revenue" source="revenue" options={{ style: 'currency', currency: 'USD' }} />
      <DateField label="Registration date" source="registrationDate" />
      <TextField label="Marketplace status" source="marketplaceApprovalStatus" />
      <BooleanField label="Hidden" source="hidden" />
      <EditButton />
    </Datagrid>
  </List>
);
