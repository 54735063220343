import { FC } from 'react';
import { Datagrid, List, ListProps, NumberField, TextField } from 'react-admin';
import { EmptyList } from '../../../components/EmptyList';
import { LinkToOrdersButton } from '../../../components/LinkToOrdersButton';

export const PayoutSupplierList: FC<ListProps> = (props) => (
  <List
    bulkActionButtons={false}
    title="Select a supplier to create a payout"
    empty={<EmptyList title="No suppliers with unpaid orders" />}
    {...props}
  >
    <Datagrid>
      <TextField label="Name" source="name" />
      <NumberField label="Unpaid orders" source="unpaidOrdersCount" />
      <NumberField source="payoutDue" label="Payout due" options={{ style: 'currency', currency: 'USD' }} />
      <LinkToOrdersButton pathname="/payouts/suppliers/orders" recordName="supplierId" />
    </Datagrid>
  </List>
);
