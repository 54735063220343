import { FC } from 'react';
import { sanitizeFieldRestProps, UrlFieldProps } from 'react-admin';
import { get } from 'lodash';
import { Link, Typography } from '@material-ui/core';

const UrlField: FC<ExtendedUrlField> = ({ className, emptyText, source, linkSource, record = {}, ...rest }) => {
  const value = get(record, linkSource || '') || '';
  const name = get(record, source || '') || '';

  if (!value && emptyText) {
    return (
      <Typography component="span" variant="body2" className={className} {...sanitizeFieldRestProps(rest)}>
        {emptyText}
      </Typography>
    );
  }

  return (
    <Link
      className={className}
      href={value.startsWith('http') ? value : `https://${value}`}
      target="_blank"
      {...sanitizeFieldRestProps(rest)}
    >
      {name || value}
    </Link>
  );
};

export interface ExtendedUrlField extends UrlFieldProps {
  linkSource?: string;
}

export default UrlField;
