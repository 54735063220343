import { stringify } from 'query-string';
import { Store } from '@material-ui/icons';
import { Link, Button, ButtonProps } from 'react-admin';

export const LinkToOrdersButton = ({
  record,
  pathname,
  recordName,
}: ButtonProps & { pathname: string; recordName: string }) => {
  return (
    <Button
      label="Show orders"
      component={Link}
      to={{
        pathname,
        search: stringify({
          page: 1,
          perPage: 10,
          filter: JSON.stringify({
            [recordName]: record?.id,
          }),
        }),
        state: { preferences: record?.payoutPreferences, supplierName: record?.name },
      }}
    >
      <Store />
    </Button>
  );
};
