import { Typography } from '@material-ui/core';
import { FC, useCallback } from 'react';
import { Create, CreateProps, useRedirect, useUnselectAll } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { PayoutForm } from './PayoutForm';
import { ISupplierPayoutPreferences } from './suppliers/ISupplierPayoutPreferences';

interface ICreatePayoutState {
  supplierId: string;
  orderIds: string[];
  preferences?: ISupplierPayoutPreferences;
}

const paymentMethod: { [key: string]: string } = {
  bank_ach_transfer: 'Bank ACH Transfer',
  paypal: 'PayPal',
};

const paymentMethodDetails = (preferences?: ISupplierPayoutPreferences) => {
  switch (preferences?.payoutMethod) {
    case 'bank_ach_transfer':
      return (
        <Typography variant="body2">
          Account Number: {preferences.achDetails?.account}, Routing Number: {preferences.achDetails?.routing}
          {preferences?.achDetails?.w9Form && (
            <>
              <br />
              <a
                href={preferences?.achDetails?.w9Form.src}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#5293fa' }}
              >
                W9 Form
              </a>
            </>
          )}
          {preferences?.achDetails?.voidBankCheck && (
            <>
              <br />
              <a
                href={preferences?.achDetails?.voidBankCheck.src}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#5293fa' }}
              >
                Void bank check
              </a>
            </>
          )}
        </Typography>
      );
    case 'paypal':
      return <Typography variant="body2">PayPal Email: {preferences.paypalEmail}</Typography>;
    default:
      return null;
  }
};

const Aside = ({ record }: Pick<CreateProps, 'record'>) =>
  record ? (
    <div style={{ width: 200, margin: '1em', order: -1 }}>
      <Typography variant="h6">Details</Typography>
      <Typography variant="body2">Creating payout for {record.orderIds?.length} supplier&apos;s orders</Typography>
      <Typography variant="body2">
        This suppliers preferred payout method is {paymentMethod[record.preferences?.payoutMethod] || 'not specified'}
      </Typography>
      {paymentMethodDetails(record.preferences)}
    </div>
  ) : null;

export const PayoutCreate: FC<CreateProps> = (props) => {
  const redirect = useRedirect();
  const unselectAll = useUnselectAll();
  const { state } = useLocation<{
    record?: ICreatePayoutState;
  }>();
  const ordersResourceName = 'payouts/suppliers/orders';

  const handleSuccess = useCallback(() => {
    unselectAll(ordersResourceName);
    redirect('/payouts');
  }, [redirect, unselectAll, ordersResourceName]);

  // check if user navigated to this page from the supplier's order list with required state
  if (!state?.record || !state.record.orderIds || !state.record.orderIds.length || !state.record.supplierId) {
    redirect('/payouts');
    return null;
  }

  return (
    <Create title="Create a payout" aside={<Aside />} {...props} onSuccess={handleSuccess}>
      <PayoutForm />
    </Create>
  );
};
