import { AuthProvider } from 'react-admin';
import { backendUrl } from './consts';

const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    const response = await fetch(`${backendUrl}/auth`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password }),
    });
    if (!response.ok) {
      const { msg } = await response.json();
      throw new Error(msg);
    }
    const { token, id, fullName } = await response.json();
    localStorage.setItem('token', token);
    localStorage.setItem('auth', JSON.stringify({ id, fullName }));
  },
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('auth');
    return Promise.resolve();
  },
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      localStorage.removeItem('auth');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => (localStorage.getItem('token') ? Promise.resolve() : Promise.reject()),
  getPermissions: () => Promise.resolve([]),
  getIdentity: () => {
    const { id, fullName } = JSON.parse(localStorage.getItem('auth') || '{}');

    return Promise.resolve({ id, fullName });
  },
};

export default authProvider;
