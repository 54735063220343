import { MonetizationOn } from '@material-ui/icons';
import { ResourceProps } from 'react-admin';
import { PayoutCreate } from './PayoutCreate';
import { PayoutEdit } from './PayoutEdit';
import { PayoutList } from './PayoutList';
import { PayoutShow } from './PayoutShow';

const options = {
  label: 'Payouts',
  subMenuName: 'Payouts',
};

export const payoutsResourceProps: Omit<ResourceProps, 'name'> = {
  list: PayoutList,
  create: PayoutCreate,
  edit: PayoutEdit,
  show: PayoutShow,
  icon: MonetizationOn,
  options,
};
