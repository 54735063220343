import { FC } from 'react';
import { DateField, NumberField, Show, ShowProps, SimpleShowLayout, TextField } from 'react-admin';
import { LinkToOrdersButton } from '../../components/LinkToOrdersButton';

export const PayoutShow: FC<ShowProps> = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <DateField label="Payout creation date" source="createdAt" />
      <TextField label="Status" source="status" />
      <TextField label="Confirmation ID" source="confirmationId" />
      <NumberField source="payoutDue" label="Payout due" options={{ style: 'currency', currency: 'USD' }} />
      <NumberField label="Orders amount" source="amount" options={{ style: 'currency', currency: 'USD' }} />
      <NumberField label="Adjust. amount" source="adjustmentAmount" options={{ style: 'currency', currency: 'USD' }} />
      <TextField label="Adjust. reason" source="adjustmentReason" />
      <NumberField label="Total payout amount" source="payoutAmount" options={{ style: 'currency', currency: 'USD' }} />
      <LinkToOrdersButton pathname="/payouts/orders" recordName="payoutId" />
    </SimpleShowLayout>
  </Show>
);
