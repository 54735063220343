import React, { FC } from 'react';
import { Filter, FilterProps, ReferenceInput, SelectInput } from 'react-admin';

export const RetailerFilters: FC<Omit<FilterProps, 'children'>> = (props) => (
  <Filter {...props}>
    <ReferenceInput source="plan" reference="plans" alwaysOn sort={{ field: 'name', order: 'ASC' }}>
      <SelectInput optionText="displayName" />
    </ReferenceInput>
  </Filter>
);
