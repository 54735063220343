import { SimpleForm, Toolbar, SimpleFormProps, BooleanInput, TextInput } from 'react-admin';

export const RetailerForm = (props: Omit<SimpleFormProps, 'children'>) => (
  <SimpleForm {...props} toolbar={<Toolbar alwaysEnableSaveButton />}>
    <TextInput source="name" disabled />
    <BooleanInput source="isLegacyPaidVisible" label="Show Legacy Paid Plan" />
    <BooleanInput source="isLegacyFreeVisible" label="Show Legacy Free Plan" />
    <BooleanInput source="isEnterprise1Visible" label="Show Enterprise 1 Plan" />
    <BooleanInput source="isEnterprise2Visible" label="Show Enterprise 2 Plan" />
  </SimpleForm>
);
