import { FC } from 'react';
import { Datagrid, DateField, List, ListProps, NumberField, TextField } from 'react-admin';

export const PayoutOrderList: FC<ListProps> = (props) => (
  <List bulkActionButtons={false} {...props}>
    <Datagrid>
      <TextField label="Name" source="name" />
      <TextField label="Retailer name" source="retailerName" />
      <TextField label="Retailer purchase order #" source="retailerPurchaseOrderName" />
      <DateField label="Order creation date" source="date" />
      <DateField label="Order fulfilled date" source="orderFulfilledDate" />
      <NumberField label="Crowdship fee, %" source="crowdshipFeePercent" />
      <NumberField
        label="Crowdship fee, $"
        source="crowdshipFeeAmount"
        options={{ style: 'currency', currency: 'USD' }}
      />
      <NumberField label="Line items total" source="lineItemTotal" options={{ style: 'currency', currency: 'USD' }} />
      <NumberField label="Shipping total" source="shippingTotal" options={{ style: 'currency', currency: 'USD' }} />
      <NumberField
        label="Handling fee total"
        source="handlingFeeTotal"
        options={{ style: 'currency', currency: 'USD' }}
      />
      <NumberField source="amountDue" label="Amount due" options={{ style: 'currency', currency: 'USD' }} />
    </Datagrid>
  </List>
);
