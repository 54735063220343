import { ComponentType, FC, useState } from 'react';
import { getResources, MenuItemLink, MenuProps, ReduxState, ResourceDefinition, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { SubMenu } from './SubMenu';

type MenuName = 'Payouts';

interface IResourceOption {
  label: string;
  subMenuName: MenuName;
  subMenuIcon: ComponentType<any>;
}

interface IResource extends Omit<ResourceDefinition, 'options'> {
  options?: IResourceOption;
}

interface ISubMenusDescription {
  [key: string]: IResource[];
}

const useStyles = makeStyles({
  sideMenuWrapper: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 48px - 1.5em)',
  },
});

export const Menu: FC<MenuProps> = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const resources: ResourceDefinition[] = useSelector(getResources);
  const sidebarOpen = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);

  const [subMenuState, setSubMenuState] = useState({
    Payouts: true,
  });

  const handleToggle = (menu: MenuName) => {
    setSubMenuState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  const sidebarElement = document.getElementById('sidebar');
  const contentSectionElement =
    sidebarElement && sidebarElement.nextSibling && (sidebarElement.nextSibling.firstChild as HTMLElement | null);
  const contentSectionElementTopPosition =
    contentSectionElement && Math.abs(contentSectionElement.getBoundingClientRect().y) - window.pageYOffset;
  const contentSectionElementHeight = contentSectionElement && contentSectionElement.scrollHeight;

  const menuWrapperClass =
    (contentSectionElementTopPosition !== null &&
      contentSectionElementHeight !== null &&
      contentSectionElementTopPosition + contentSectionElementHeight > document.documentElement.clientHeight) ||
    document.documentElement.clientWidth < 600
      ? ''
      : classes.sideMenuWrapper;

  const renderMenuItem = (resource: IResource) => (
    <MenuItemLink
      key={resource.name}
      to={`/${resource.name}`}
      sidebarIsOpen={sidebarOpen}
      primaryText={(resource.options && resource.options.label) || translate(`resources.${resource.name}.name`)}
      leftIcon={resource.icon && <resource.icon />}
    />
  );

  const renderSubMenu = (res: IResource[]) => {
    const menuOptions = res[0].options;
    if (!menuOptions) return null;

    return (
      <SubMenu
        key={menuOptions.subMenuName}
        handleToggle={() => handleToggle(menuOptions.subMenuName)}
        isOpen={subMenuState[menuOptions.subMenuName]}
        sidebarIsOpen={sidebarOpen}
        name={menuOptions.subMenuName}
        icon={<menuOptions.subMenuIcon />}
      >
        {res.map((resource) => renderMenuItem(resource))}
      </SubMenu>
    );
  };

  const findSubMenuResources = (subMenuName: MenuName): IResource[] =>
    resources.filter((resource) => resource.options?.subMenuName === subMenuName);

  const renderMenuEntry = (resource: IResource, subMenus: ISubMenusDescription) =>
    // eslint-disable-next-line no-nested-ternary
    resource.options?.subMenuName
      ? resource.options.subMenuIcon
        ? renderSubMenu(subMenus[resource.options.subMenuName])
        : null
      : renderMenuItem(resource);

  const subMenusDesc: ISubMenusDescription = {
    Payouts: findSubMenuResources('Payouts'),
  };

  return (
    <div className={menuWrapperClass}>
      {resources.map((resource) => resource.hasList && resource.icon && renderMenuEntry(resource, subMenusDesc))}
    </div>
  );
};
