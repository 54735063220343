import { AccessTime } from '@material-ui/icons';
import { FilterList, FilterListItem } from 'react-admin';
import { endOfYesterday, endOfToday, startOfWeek, subWeeks, startOfMonth, subMonths } from 'date-fns';

export const DateCreatedFilter = () => (
  <FilterList label="Created" icon={<AccessTime />}>
    <FilterListItem
      label="Today"
      value={{
        startDate: endOfYesterday().toISOString(),
        endDate: endOfToday().toISOString(),
      }}
    />
    <FilterListItem
      label="This week"
      value={{
        startDate: startOfWeek(new Date()).toISOString(),
        endDate: endOfToday().toISOString(),
      }}
    />
    <FilterListItem
      label="Last week"
      value={{
        startDate: subWeeks(startOfWeek(new Date()), 1).toISOString(),
        endDate: startOfWeek(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      label="This month"
      value={{
        startDate: startOfMonth(new Date()).toISOString(),
        endDate: endOfToday().toISOString(),
      }}
    />
    <FilterListItem
      label="Last month"
      value={{
        startDate: subMonths(startOfMonth(new Date()), 1).toISOString(),
        endDate: startOfMonth(new Date()).toISOString(),
      }}
    />
  </FilterList>
);
