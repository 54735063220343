import { Card as MuiCard, CardContent, withStyles } from '@material-ui/core';
import { DateCreatedFilter } from '../../../components/DateCreatedFilter';

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

export const PayoutOrderFilterSidebar = () => (
  <Card>
    <CardContent>
      <DateCreatedFilter />
    </CardContent>
  </Card>
);
