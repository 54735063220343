import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#90CAF9',
    },
    secondary: {
      main: '#71009E',
    },
    type: 'dark',
  },
  overrides: {
    MuiDrawer: { root: { paddingTop: 16 } },
  },
});
