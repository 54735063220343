import { MonetizationOn, PermIdentity } from '@material-ui/icons';
import { ResourceProps } from 'react-admin';
import { PayoutSupplierList } from './PayoutSupplierList';

const options = {
  label: 'Suppliers',
  subMenuName: 'Payouts',
  subMenuIcon: MonetizationOn,
};

export const payoutsSuppliersResourceProps: Omit<ResourceProps, 'name'> = {
  list: PayoutSupplierList,
  icon: PermIdentity,
  options,
};
